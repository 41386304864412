<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Settings</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-toolbar flat dense dark color="accent">
              <v-toolbar-title>Website Functionality Toggles</v-toolbar-title>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Is the Lambing Experience currently open to bookings?</th>
                  <td>
                    <v-switch
                      inset
                      color="green"
                      v-model="can_book_lambing_experience"
                      :label="can_book_lambing_experience ? 'Yes' : 'No'"
                      :loading="can_book_lambing_experience_loading"
                      @click="
                        updateSetting('can_book_lambing_experience', 'boolean')
                      "
                    ></v-switch>
                  </td>
                </tr>
                <tr>
                  <th>Link to Book Lambing Experience Tickets</th>
                  <td>
                    <v-text-field
                      v-model="link_to_book_lambing_experience"
                      outlined
                      dense
                      hide-details
                      :loading="link_to_book_lambing_experience_loading"
                      append-outer-icon="mdi-content-save"
                      @click:append-outer="
                        updateSetting('link_to_book_lambing_experience', 'text')
                      "
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <th>Date of Lambing Experience</th>
                  <td>
                    <v-menu
                      ref="lambing_experience_date_menu"
                      v-model="lambing_experience_date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="lambing_experience_date_formatted"
                          prepend-inner-icon="mdi-calendar"
                          outlined
                          class="mt-1"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          hide-details
                          background-color="white"
                          append-outer-icon="mdi-content-save"
                          @click:append-outer="
                            updateSetting('lambing_experience_date', 'text')
                          "
                        ></v-text-field>
                      </template>

                      <v-date-picker
                        v-model="lambing_experience_date"
                        no-title
                        @input="lambing_experience_date_menu = false"
                      ></v-date-picker>
                    </v-menu>

                    <!-- <v-menu
                      ref="lambing_experience_date_menu"
                      v-model="lambing_experience_date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="lambing_experience_date_formatted"
                          prepend-inner-icon="mdi-calendar"
                          outlined
                          class="mt-1"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @click:append-outer="
                            updateSetting('lambing_experience_date', 'text')
                          "
                        />
                      </template>

                      <v-date-picker
                        elevation="24"
                        v-model="lambing_experience_date"
                        no-title
                        view-mode="year"
                        year="2025"
                        @input="lambing_experience_date_menu = false"
                      ></v-date-picker>
                    </v-menu> -->

                    <!--
                    <v-menu
                      ref="lambing_experience_date_menu"
                      v-model="lambing_experience_date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="lambing_experience_date_formatted"
                          outlined
                          dense
                          hide-details
                          readonly
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          append-outer-icon="mdi-content-save"
                          @click:append-outer="
                            updateSetting('lambing_experience_date', 'text')
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="lambing_experience_date"
                        no-title
                        @input="lambing_experience_date_menu = false"
                      ></v-date-picker>
                    </v-menu> -->
                  </td>
                </tr>
                <tr>
                  <th>Ticket Cost for Lambing Experience</th>
                  <td>
                    <v-text-field
                      v-model="lambing_experience_ticket_cost"
                      type="number"
                      outlined
                      dense
                      hide-details
                      min="0.00"
                      step="1.00"
                      prefix="£"
                      :loading="lambing_experience_ticket_cost_loading"
                      append-outer-icon="mdi-content-save"
                      @click:append-outer="
                        updateSetting('lambing_experience_ticket_cost', 'text')
                      "
                    ></v-text-field>
                  </td>
                </tr>

                <tr>
                  <th>Pumpkin Experience Open?</th>
                  <td>
                    <v-switch
                      inset
                      color="green"
                      v-model="can_book_pumpkin_experience"
                      :label="can_book_pumpkin_experience ? 'Yes' : 'No'"
                      :loading="can_book_pumpkin_experience_loading"
                      @click="
                        updateSetting('can_book_pumpkin_experience', 'boolean')
                      "
                    ></v-switch>
                  </td>
                </tr>

                <tr>
                  <th>Pumpkin Text Before Title</th>
                  <td>
                    <v-text-field
                      v-model="text_pumpkin_before"
                      outlined
                      dense
                      hide-details
                      :loading="text_pumpkin_before_loading"
                      append-outer-icon="mdi-content-save"
                      @click:append-outer="
                        updateSetting('text_pumpkin_before', 'text')
                      "
                    ></v-text-field>
                  </td>
                </tr>

                <tr>
                  <th>Link to Pumpkin Tickets</th>
                  <td>
                    <v-text-field
                      v-model="link_to_book_pumpkin_experience"
                      outlined
                      dense
                      hide-details
                      :loading="link_to_book_pumpkin_experience"
                      append-outer-icon="mdi-content-save"
                      @click:append-outer="
                        updateSetting('link_to_book_pumpkin_experience', 'text')
                      "
                    ></v-text-field>
                  </td>
                </tr>

                <tr>
                  <th>Link to ASN Pumpkin Tickets</th>
                  <td>
                    <v-text-field
                      v-model="link_pumpkin_asn"
                      outlined
                      dense
                      hide-details
                      :loading="link_pumpkin_asn_loading"
                      append-outer-icon="mdi-content-save"
                      @click:append-outer="
                        updateSetting('link_pumpkin_asn', 'text')
                      "
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Settings",
          disabled: true,
        },
      ],
      can_book_lambing_experience: false,
      can_book_lambing_experience_loading: false,
      can_book_pumpkin_experience: false,
      can_book_pumpkin_experience_loading: false,
      link_to_book_lambing_experience: null,
      link_to_book_lambing_experience_loading: false,
      link_to_book_pumpkin_experience: null,
      link_to_book_pumpkin_experience_loading: false,
      lambing_experience_date: null,
      lambing_experience_date_loading: false,
      lambing_experience_date_menu: false,
      lambing_experience_ticket_cost: null,
      lambing_experience_ticket_cost_loading: false,
      text_pumpkin_before: null,
      text_pumpkin_before_loading: false,
      link_pumpkin_asn: null,
      link_pumpkin_asn_loading: false,
    };
  },

  computed: {
    settings() {
      return this.$store.getters["arnprior/settingsStore/all"];
    },

    lambing_experience_date_formatted() {
      return this.formatDateYearMonthDay(this.lambing_experience_date);
    },
  },

  mounted() {
    if (this.settings.some((s) => s.name === "can_book_lambing_experience")) {
      const setting = this.settings.find(
        (s) => s.name === "can_book_lambing_experience"
      );

      this.can_book_lambing_experience = setting.content == "1" ? true : false;
    }

    if (
      this.settings.some((s) => s.name === "link_to_book_lambing_experience")
    ) {
      const setting = this.settings.find(
        (s) => s.name === "link_to_book_lambing_experience"
      );

      this.link_to_book_lambing_experience = setting.content;
    }

    if (this.settings.some((s) => s.name === "can_book_pumpkin_experience")) {
      const setting = this.settings.find(
        (s) => s.name === "can_book_pumpkin_experience"
      );

      this.can_book_pumpkin_experience = setting.content == "1" ? true : false;
    }

    if (
      this.settings.some((s) => s.name === "link_to_book_pumpkin_experience")
    ) {
      const setting = this.settings.find(
        (s) => s.name === "link_to_book_pumpkin_experience"
      );

      this.link_to_book_pumpkin_experience = setting.content;
    }

    if (this.settings.some((s) => s.name === "lambing_experience_date")) {
      const setting = this.settings.find(
        (s) => s.name === "lambing_experience_date"
      );

      this.lambing_experience_date = setting.content;
    }

    if (
      this.settings.some((s) => s.name === "lambing_experience_ticket_cost")
    ) {
      const setting = this.settings.find(
        (s) => s.name === "lambing_experience_ticket_cost"
      );

      this.lambing_experience_ticket_cost = setting.content;
    }

    if (this.settings.some((s) => s.name === "text_pumpkin_before")) {
      const setting = this.settings.find(
        (s) => s.name === "text_pumpkin_before"
      );

      this.text_pumpkin_before = setting.content;
    }

    if (this.settings.some((s) => s.name === "link_pumpkin_asn")) {
      const setting = this.settings.find((s) => s.name === "link_pumpkin_asn");

      this.link_pumpkin_asn = setting.content ?? null;
    }
  },

  methods: {
    updateSetting(name, type) {
      const appId = this.$route.params.id;
      this[name + "_loading"] = true;

      const content =
        type === "boolean" ? (this[name] ? "1" : "0") : this[name];

      this.$store
        .dispatch("arnprior/settingsStore/updateSettings", {
          appId,
          fields: {
            name,
            content,
          },
        })
        .then(() => (this[name + "_loading"] = false));
    },
  },
};
</script>
